import { updateStatus } from "../API/websocket"
import { Status } from "../Models/Enums/Status"

interface YourStatusProps {
  status: Status
}

export function YourStatus(props: YourStatusProps) {

  const { status } = props
  return (
    <div style={{maxWidth:"400px"}} className='m-auto'>
      <div className="text-xl font-bold mt-10">Set your status</div>
      <div className="m-auto mr-5 flex flex-wrap text-center mt-5">
        <button
          className={`border-2 border-black px-4 py-1 rounded-xl ml-3 flex-grow ${
            status === Status.OFFLINE ? 'bg-gray-700 text-white' : ''
          }`}
          onClick = {() => updateStatus(Status.OFFLINE)}
        >
          Offline
        </button>
        <button
          className={`border-2 border-black px-4 py-1 rounded-xl ml-3 flex-grow ${
            status === Status.ONLINE ? 'bg-green-700 text-white' : ''
          }`}
          onClick = {() => updateStatus(Status.ONLINE)}
        >
          Online
        </button>
        <button
          className={`border-2 border-black px-4 py-1 rounded-xl ml-3 flex-grow ${
            status === Status.DEEP_WORK ? 'bg-red-700 text-white' : ''
          }`}
          onClick = {() => updateStatus(Status.DEEP_WORK)}
        >
          DeepWork
        </button>
      </div>
    </div>
  )
}
