export function addConnection(friendCode: string) {
  return new Promise<void>((res, rej) => {
    fetch('/connections/create?friendCode=' + friendCode, {
      method: 'POST',
    })
      .then((_) => res())
      .catch((e) => rej(e))
  })
}

export function acceptConnection(connectionId: number) {
  return new Promise<void>((res, rej) => {
    fetch('/connections/accept?connectionId=' + connectionId, {
      method: 'POST',
    })
      .then((_) => res())
      .catch((e) => rej(e))
  })
}

export function removeConnection(userId: number) {
  return new Promise<void>((res, rej) => {
    fetch('/connections/remove?userId' + userId, {
      method: 'POST',
    })
      .then((_) => res())
      .catch((e) => rej(e))
  })
}