import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HomePage } from './Pages/HomePage'
import { EmailPage } from './Pages/EmailPage'
import { NotFoundPage } from './Pages/NotFound'
import { PageWrapper } from './Components/PageWrapper'
import { CodePage } from './Pages/CodePage'
import { useEffect } from 'react'
import { getCookie } from './Helpers/Cookie'
import { SettingsPage } from './Pages/SettingsPage'
import { FriendsPage } from './Pages/FriendsPage'
import { ContextWrapper } from './Components/Context'
import PublicPage from './Pages/PublicPage'

function App() {
  useEffect(() => {
    if (
      !getCookie('token') &&
      !window.location.pathname.startsWith('/code') &&
      window.location.pathname !== '/email' &&
      window.location.pathname !== '/'
    ) {
      window.location.pathname = '/email'
    }
  }, [])

  return (
    <div>
      <ContextWrapper>
        <PageWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<PublicPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/email" element={<EmailPage />} />
              <Route path="/code" element={<CodePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/friends" element={<FriendsPage />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </PageWrapper>
      </ContextWrapper>
    </div>
  )
}

export default App
