interface ButtonProps {
  disable?: boolean
  onClick: VoidFunction
  children?: any
  invert?: boolean
}

export function Button(props: ButtonProps) {
  const { disable, onClick, children, invert } = props

  var className =
    'bg-black text-white px-10 py-3 rounded-full hover:bg-gray-800 disabled:bg-gray-500'
  if (invert) {
    className =
      'bg-white text-black px-10 py-3 rounded-full hover:bg-gray-50 disabled:bg-gray-300'
  }
  return (
    <button
      className={className}
      disabled={disable ?? false}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
