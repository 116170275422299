import { eraseCookie } from '../Helpers/Cookie'

interface NavbarProps {
  title: string
}

export function Navbar(props: NavbarProps) {
  const { title } = props
  return (
    <div className="flex flex-wrap mb-10 gap-10">
      <div className="text-4xl font-bold grow text-left">{title}</div>
      <div className="flex justify-between w-fit gap-6">
        <RedirectButton icon="fas fa-house-user fa-lg" redirect="/home" />
        <RedirectButton icon="fa fa-cogs fa-lg" redirect="/settings" />
        <RedirectButton icon="fas fa-user-plus" redirect="/friends" />
        <LogoutButton />
      </div>
    </div>
  )
}

function LogoutButton() {
  return (
    <div className="text-right">
      <button
        onClick={() => {
          eraseCookie('token')
          eraseCookie('user')
          window.location.reload()
        }}
      >
        <i className="fas fa-sign-out-alt fa-lg"></i>
      </button>
    </div>
  )
}

interface RedirectButtonProps {
  icon: string
  redirect: string
}

function RedirectButton(props: RedirectButtonProps) {
  const { icon, redirect } = props
  return (
    <div className="text-right">
      <button
        onClick={() => {
          window.location.pathname = redirect
        }}
      >
        <i className={icon}></i>
      </button>
    </div>
  )
}
