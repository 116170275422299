import { useState } from 'react'
import { addConnection } from '../API/connections'
export function AddFriends() {
  const [code, setCode] = useState('')

  return (
    <div>
      <div className="text-xl font-bold">Add Friends!</div>
      <div className="flex flex-row mt-5 grow">
        <input
          type="email"
          className="grow form-input px-4 py-3 rounded-xl border-black border-2 focus:border-black mr-5"
          placeholder="Your friend's code"
          onChange={(event) => {
            setCode(event.target.value)
          }}
        />
        <button
          className="rounded-xl bg-black text-white px-5 py-3 hover:bg-gray-800 disabled:bg-gray-500"
          onClick={() => {
            addConnection(code)
          }}
        >
          Request
        </button>
      </div>
    </div>
  )
}
