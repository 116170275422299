import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../API/auth'
import { Button } from '../Components/Button'
const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export function EmailPage() {
  const [email, setEmail] = useState<string>('')
  const navigate = useNavigate()
  return (
    <div className="flex flex-col h-36 mx-4">
      <div className="grow">
        <div className="text-4xl text-center">Let's get started!</div>
        <div className="text-large text-center">
          Enter your email address to get a one-time login code.
        </div>
      </div>
      <div className="flex flex-row flex-wrap m-auto gap-5">
        <input
          type="email"
          className="form-input px-4 py-3 rounded-full focus:border-gray-400 w-80 m-auto mt-4"
          placeholder="Email address"
          onChange={(event) => {
            setEmail(event.target.value)
          }}
        />
        <div className="m-auto mt-4">
          <Button
            disable={!validateEmail(email)}
            onClick={() => {
              login(email)
                .then((_) =>
                  navigate({
                    pathname: '/code',
                    search: `?email=${email}`,
                  })
                )
                .catch((e) => console.log(e))
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}
