import { useContext } from 'react'
import { UserProfileContext } from '../Components/Context'
import { Navbar } from '../Components/Navbar'

export function SettingsPage() {
  const userProfile = useContext(UserProfileContext)

  return (
    <div className="w-screen min-h-screen max-h-fit p-10">
      <Navbar title="Deepwork Settings" />
      TODO modify name and email
      <br />
      Your friend code: {userProfile.friendCode}
    </div>
  )
}
