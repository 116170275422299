import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { getCookie } from '../Helpers/Cookie'
import { Opcode } from '../Models/Enums/Opcodes'
import { HelloData } from '../Models/GatewayData/HelloData'
import { IdentifyData } from '../Models/GatewayData/IdentifyData'
import { GatewayPayload } from '../Models/Payload'
import { API_URL } from './helper'
import { Observable } from 'rxjs'
import { Status } from '../Models/Enums/Status'
import { UpdateStatusData } from '../Models/GatewayData/UpdateStatusData'

var client: W3CWebSocket

export function initWebsocketConnection(): Observable<GatewayPayload<any>> {
  client = new W3CWebSocket(
    `wss://${API_URL}/api/gateway`,
    undefined,
    undefined,
    { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
  )
  client.onopen = () => {
    console.log('WebSocket Client Connected')
  }

  return new Observable((sub) => {
    client.onmessage = (message) => {
      console.log(JSON.parse(message.data as string))
      var data: GatewayPayload<any> = JSON.parse(message.data as string)
      sub.next(data)
      if (data.op === Opcode.HELLO) helloHandler(client, data)
    }
    window.addEventListener('beforeunload', (e) => {
      client.close()
    })
  })
}

// update current user status
export function updateStatus(status: Status) {
  const statusData: UpdateStatusData = {
    status: status
  }
  const payload: GatewayPayload<UpdateStatusData> = {
    op: Opcode.UPDATE_STATUS,
    d: statusData
  }
  client.send(JSON.stringify(payload))
}

// initial handshake
function helloHandler(client: W3CWebSocket, data: GatewayPayload<any>) {
  var helloData = data as GatewayPayload<HelloData>
  // Schedule hearbeat
  let heartbeat: GatewayPayload<any> = {
    op: Opcode.HEARTBEAT,
  }
  window.setInterval(function () {
    client.send(JSON.stringify(heartbeat))
  }, Math.max(helloData.d!.heartbeatInterval - 1000, 1000))

  // Identify
  let identify: GatewayPayload<IdentifyData> = {
    op: Opcode.IDENTIFY,
    d: {
      token: getCookie('token') || '',
    },
  }
  client.send(JSON.stringify(identify))
}
