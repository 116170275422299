import { Status } from '../Models/Enums/Status'

interface UserCellProps {
  user: User
}

interface User {
  id: number
  displayName: string
  status: Status
}

export function UserCell(props: UserCellProps) {
  const { user } = props
  return (
    <div style={{ maxWidth: '400px' }}>
      <div className="w-full rounded-xl border-2 border-black border-solid p-5 mt-5">
        <div className="flex flex-wrap h-full">
          <div className="rounded-full border-black border-2 h-14 w-14 p-3 mt-auto mb-auto text-lg">
            {user.displayName.charAt(0).toUpperCase()}
          </div>
          <div className="my-auto ml-4 text-xl">
            <div>{user.displayName}</div>
          </div>
          <div className="m-auto mr-5 flex flex-wrap text-right">
            <div
              className={`border-2 border-black px-4 py-1 rounded-xl ml-3 ${
                user.status === Status.OFFLINE
                  ? 'bg-gray-700 text-white'
                  : 'hidden'
              }`}
            >
              Offline
            </div>
            <div
              className={`border-2 border-black px-4 py-1 rounded-xl ml-3 ${
                user.status === Status.ONLINE
                  ? 'bg-green-700 text-white'
                  : 'hidden'
              }`}
            >
              Online
            </div>
            <div
              className={`border-2 border-black px-4 py-1 rounded-xl ml-3 ${
                user.status === Status.DEEP_WORK
                  ? 'bg-red-700 text-white'
                  : 'hidden'
              }`}
            >
              DeepWork
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
