import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getToken } from '../API/auth'
import { login } from '../API/auth'
import { Button } from '../Components/Button'
import { setCookie } from '../Helpers/Cookie'

export function CodePage() {
  const [code, setCode] = useState<string>('')
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState<string>('')
  const [sent, setSent] = useState<boolean>(false)
  const navigate = useNavigate()
  useEffect(() => {
    const e = searchParams.get('email')
    if (e) {
      setEmail(e)
    } else {
      window.location.pathname = '/email'
    }
  }, [searchParams])

  return (
    <div className="flex flex-col h-44 mx-4">
      <div className="grow">
        <div className="text-4xl text-center">Email verification</div>
        <div className="text-large text-center">We sent a code to {email}</div>
      </div>
      <div className="flex flex-row flex-wrap m-auto gap-5 mt-5">
        <input
          type="text"
          className="form-input px-4 py-3 rounded-full focus:border-gray-400 w-80 m-auto"
          placeholder="One-time code"
          onChange={(event) => {
            setCode(event.target.value)
          }}
        />
        <div className="m-auto">
          <Button
            disable={code === ''}
            onClick={() => {
              getToken(email, code)
                .then((t) => {
                  setCookie('token', t.token, 3)
                  setCookie('user', JSON.stringify(t.user), 3)
                  navigate('/home')
                })
                .catch((e) => console.log(e))
            }}
          >
            Login
          </Button>
        </div>
      </div>
      <div>
        <div className="text-large grow mt-4">
          {!sent ? (
            <>
              Not received?
              <button
                className="underline p-1"
                onClick={() => {
                  login(email)
                  setSent(true)
                }}
              >
                <span>Click here</span>
              </button>
              to send again
            </>
          ) : (
            <span>Code sent, check your inbox!</span>
          )}
        </div>
      </div>
    </div>
  )
}
