import { useContext, useEffect } from 'react'
import {
  BeatCountContext,
  ConnectionsContext,
  UserProfileContext,
} from '../Components/Context'
import { Navbar } from '../Components/Navbar'
import { YourStatus } from '../Components/Status'
import { UserCell } from '../Components/UserCell'
import { getCookie } from '../Helpers/Cookie'

export function HomePage() {
  const userProfile = useContext(UserProfileContext)
  const connections = useContext(ConnectionsContext)
  const beat = useContext(BeatCountContext)

  useEffect(() => {
    let u = getCookie('user')
    if (!u) {
      window.location.pathname = '/email'
    } else {
    }
  }, [userProfile, connections, beat])

  useEffect(() => {}, [])

  const Home = () => {
    return (
      <div className="w-screen min-h-screen max-h-fit p-10">
        <div>
          <Navbar title={`Welcome, ${userProfile.displayName}`} />
          <div className="w-max mx-auto">
            <div className="mb-4">
              <YourStatus status={userProfile.status} />
            </div>
            <div className="text-xl font-bold">Deep Work Statuses</div>
            <div>
              {Object.values(connections).map((u, index) => (
                <UserCell key={index} user={u} />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {userProfile.id !== -1 ? (
        <Home />
      ) : (
        <div className="flex gap-3 text-gray-400">
          <span className="flex h-5 w-5">
            <span className="animate-ping relative inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
          </span>
          {/* Loading... */}
        </div>
      )}
    </div>
  )
}
