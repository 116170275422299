import { AddFriends } from '../Components/AddFriends'
import { Navbar } from '../Components/Navbar'
import { PendingInvites } from '../Components/PendingInvites'

export function FriendsPage() {
  return (
    <div className="w-screen min-h-screen max-h-fit p-10">
      <Navbar title="Add Friends!" />
      <div className="w-max mx-auto">
        <div className="mb-4">
          <AddFriends />
        </div>
        <div>
          <PendingInvites />
        </div>
      </div>
    </div>
  )
}
