import { createContext, ReactNode, useEffect, useState } from 'react'
import { initWebsocketConnection } from '../API/websocket'
import { EventType } from '../Models/Enums/EventType'
import { Opcode } from '../Models/Enums/Opcodes'
import { Status } from '../Models/Enums/Status'
import { ConnectionRequestNotification } from '../Models/GatewayData/ConnectionRequestNotification'
import { IdentifiedData } from '../Models/GatewayData/IdentifiedData'
import { StatusUpdateData } from '../Models/GatewayData/StatusUpdateData'
import { UserCreateData } from '../Models/GatewayData/UserCreateData'

interface ContextWrapperProps {
  children: ReactNode
}

// Contexts
export const BeatCountContext = createContext(false)
export const UserProfileContext = createContext<IdentifiedData>({
  id: -1,
  displayName: '',
  status: Status.ONLINE,
  friendCode: '',
  numExistingConnections: -1,
  pendingConnections: [],
})
export const PendingConnectionsContext = createContext<{
  [id: string]: ConnectionRequestNotification
}>({})
export const ConnectionsContext = createContext<{
  [id: string]: UserCreateData
}>({})

// Wrapper
export function ContextWrapper(props: ContextWrapperProps) {
  const [beat, setBeat] = useState(false)
  const [profile, setProfile] = useState<IdentifiedData>({
    id: -1,
    displayName: 'unknown',
    status: Status.ONLINE,
    friendCode: '',
    numExistingConnections: -1,
    pendingConnections: [],
  })
  const [pending, setPending] = useState<{
    [id: string]: ConnectionRequestNotification
  }>({})
  const [connections, setConnections] = useState<{
    [id: string]: UserCreateData
  }>({})

  useEffect(() => {
    initWebsocketConnection().subscribe((data) => {
      console.log('Called with ', data)
      switch (data.op) {
        case Opcode.IDENTIFIED:
          const payload_ID = data.d as IdentifiedData
          setProfile(payload_ID)
          var dict: { [id: string]: ConnectionRequestNotification } = {}
          payload_ID.pendingConnections.forEach(
            (c) => (dict[c.connectionId] = c)
          )
          setPending(dict)
          break
        case Opcode.DISPATCH:
          switch (data.t) {
            case EventType.USER_CREATE:
              const payload_UC = data.d as UserCreateData
              setConnections((c) => {
                c[payload_UC.id] = payload_UC
                return c
              })
              break
            case EventType.STATUS_UPDATE:
              const payload_SU = data.d as StatusUpdateData
              setProfile((p) => ({
                id: p.id,
                displayName: p.displayName,
                status:
                  p.id === payload_SU.userId ? payload_SU.status : p.status,
                friendCode: p.friendCode,
                numExistingConnections: p.numExistingConnections,
                pendingConnections: p.pendingConnections,
              }))
              if (payload_SU.userId in connections) {
                setConnections((c) => {
                  c[payload_SU.userId].status = payload_SU.status
                  return c
                })
              }
              break
          }
          break
        default:
          break
      }
      setBeat((b) => !b)
    })
  }, [connections])

  const { children } = props
  return (
    <BeatCountContext.Provider value={beat}>
      <UserProfileContext.Provider value={profile}>
        <PendingConnectionsContext.Provider value={pending}>
          <ConnectionsContext.Provider value={connections}>
            <>{children}</>
          </ConnectionsContext.Provider>
        </PendingConnectionsContext.Provider>
      </UserProfileContext.Provider>
    </BeatCountContext.Provider>
  )
}
