const companies: [{ image: string; url: string }] = [
  { image: 'terra.png', url: 'https://tryterra.co' },
]

function Nav() {
  return (
    <div className="flex justify-between">
      <div className="text-2xl font-bold">Deep Work</div>
      <div
        className="cursor-pointer py-3 px-10 rounded-xl text-white bg-blue-700"
        onClick={() => {
          window.location.pathname = 'home'
        }}
      >
        Login
      </div>
    </div>
  )
}

function MainContent() {
  return (
    <div
      className="flex flex-wrap justify-between py-16 gap-7 m-auto"
      style={{ maxWidth: '1200px' }}
    >
      <div className="text-left grid grid-col gap-7 justify-self-start h-fit">
        <div className="text-sm font-semibold text-gray-500">
          NO MORE DISTRACTIONS
        </div>
        <div className="text-6xl font-bold">
          <div>Deep Work</div>
          <div>For Teams</div>
        </div>
        <div className="text-ellipsis" style={{ maxWidth: '450px' }}>
          Even with muting notifications, your team might wander over and ask you
          in-person for help. DeepWork allows you to share your focus-mode
          schedule
        </div>
        <div
          className="cursor-pointer py-3 px-10 rounded-xl text-white bg-blue-700 w-fit"
          onClick={() => {
            window.location.pathname = 'home'
          }}
        >
          Try It!
        </div>
      </div>
      <div className="items-center">
        <img src="/assets/dummy.png" alt="logo" className="m-auto min-w-full" />
      </div>
    </div>
  )
}

function UsedBy() {
  return (
    <div className="grid grid-col gap-7">
      <div className="m-auto text-lg font-bold max-w-fit">
        Trusted by individuals and teams at
        <br />
        the world's best companies
      </div>
      <div className="flex">
        {companies.map((c, index) => (
          <img
            key={index}
            src={`/assets/${c.image}`}
            alt="logo"
            onClick={() => window.open(c.url, '_blank')}
            className="h-8 m-auto cursor-pointer"
          />
        ))}
      </div>
    </div>
  )
}

function AboutUs() {
  return (
    <div className="grid grid-col gap-7 pt-16">
      <div className="text-3xl font-bold">About Us</div>
      <div style={{ maxWidth: '450px' }} className="m-auto">
        DeepWork started as an internal tool at{' '}
        <a
          href="https://tryterra.co"
          target={'_blank'}
          className="text-blue-500 hover:underline"
          rel="noreferrer"
        >
          tryterra.co
        </a>{' '}
        to boost productivity and maximise focus-mode hours. We are now sharing
        it with you!
      </div>
      <div className="items-center">
        <img src="/assets/about.png" alt="logo" className="m-auto" />
      </div>
    </div>
  )
}

export default function PublicPage() {
  return (
    <div
      className="w-screen min-h-screen max-h-fit py-10 bg-gray-100 m-auto"
      style={{ paddingRight: '7%', paddingLeft: '7%' }}
    >
      <div className="m-auto">
        <Nav />
        <MainContent />
        <UsedBy />
        <AboutUs />
      </div>
    </div>
  )
}
