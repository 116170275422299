import fetchIntercept from 'fetch-intercept'
import { getCookie } from '../Helpers/Cookie'

export const API_URL = 'deepwork.tryterra.co'

export const AuthInterceptor = () => {
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      let headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: getCookie('token'),
      }
      config.headers = headers
      console.log(config)
      return ['https://' + API_URL + '/api' + url, config]
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error)
    },

    response: function (response) {
      // Modify the reponse object
      return response
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error)
    },
  })
}
