import { useContext } from 'react'
import { acceptConnection, removeConnection } from '../API/connections'
import { ConnectionRequestNotification } from '../Models/GatewayData/ConnectionRequestNotification'
import { UserSubPayload } from '../Models/GatewayData/UserSubPayload'
import { PendingConnectionsContext } from './Context'

interface PendingInviteProps {
  connection: ConnectionRequestNotification
}

function PendingInvite(props: PendingInviteProps) {
  const { connection } = props
  const user: UserSubPayload = connection.requestedBy

  return (
    <div className="w-full rounded-xl border-2 border-black border-solid p-5 mt-5 max-w-sm mx-auto">
      <div className="flex flex-wrap h-full">
        {/* <div className="rounded-full border-black border-2 h-14 w-14 p-3 mt-auto mb-auto text-lg">
          {user.displayName.charAt(0).toUpperCase()}
        </div> */}
        <div className="m-auto grow">
          <div>{user.displayName}</div>
          <div>{user.friendCode}</div>
        </div>
        <div className="my-auto flex grow">
          <button
            className="bg-black text-white px-4 py-1 rounded-xl hover:bg-gray-800"
            onClick={() => {
              // TODO waiting for backend endpoint
            }}
          >
            <p>&#x2717;</p>
          </button>
          <button
            className="border-2 border-black hover:bg-gray-50 px-4 py-1 rounded-xl ml-3"
            onClick={() => {
              acceptConnection(connection.connectionId)
            }}
          >
            <p>&#x2713;</p>
          </button>
        </div>
      </div>
    </div>
  )
}
export function PendingInvites() {

  const pending = useContext(PendingConnectionsContext)

  return (
    <div>
      <div className="text-xl font-bold">Pending Invites</div>
      <div>
        {Object.values(pending).map((c, index) => (
          <PendingInvite key={index} connection={c} />
        ))}
      </div>
      {Object.keys(pending).length === 0 && <i>All clear captain!</i>}
    </div>
  )
}

// {op: 5, d: {id: 4, displayName: "jarammal", status: 1}, t: "USER_CREATE"}

