import { UserSubPayload } from '../Models/GatewayData/UserSubPayload'

export interface GetTokenResponse {
  token: string
  user: UserSubPayload
}

export function login(email: string): Promise<void> {
  return new Promise<void>((res, rej) => {
    fetch('/auth/login', {
      method: 'POST',
      body: JSON.stringify({ email: email, displayName: email.split('@')[0] }),
    })
      .then((_) => res())
      .catch((e) => rej(e))
  })
}

export function getToken(
  email: string,
  code: string
): Promise<GetTokenResponse> {
  return new Promise((res, rej) => {
    fetch('/auth/getToken', {
      method: 'POST',
      body: JSON.stringify({ email: email, code: code }),
    })
      .then((r) => r.json().then((data) => res(data)))
      .catch((e) => rej(e))
  })
}
